@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --pink: #e10495;
  --navyBlue: #002492;
  --blue: #0035ff;
  --dark: #000;
}

body {
  font-family: "Manrope", sans-serif;
  background: #0c0c31;
  overflow-x: hidden;
}

.header {
  background-color: #0d0b2e;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}

.header svg {
  max-width: 200px;
}

.header .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container {
  max-width: 1600px;
  width: 100%;
  margin-inline: auto;
  padding-inline: 50px;
  overflow: hidden;
}

.navbar {
  display: flex;
  align-items: center;
  column-gap: 36px;
  height: 80px;
}

.navbar li {
  list-style: none;
  height: 100%;
}

.navbar a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 3px solid transparent;
}

.navbar a.active {
  border-bottom: 3px solid var(--pink);
}

.btnWrapper {
  display: flex;
  align-items: center;
  column-gap: 16px;
  justify-content: center;
}

.btnWrapper .btn {
  padding: 8px 24px;
  font-weight: 500;
  border-radius: 6px;
  box-shadow: 4px 4px 4px 0px rgba(225, 4, 149, 0.25);
  font-size: 16px;
  text-decoration: none;
  position: relative;
  z-index: 2;
}

.btnWrapper .btn.login {
  background: var(--pink);
  color: #fff;
  border: 1px solid #fff;
}

.btnWrapper .btn.register {
  background: #fff;
  border: 1px solid rgba(225, 4, 149, 0.5);
  color: var(--pink);
}

footer {
  display: flex;
  justify-content: center;
  padding-top: 100px;
}

.footer {
  background: #69085d;
  border-radius: 36px;
  margin: 20px;
  max-width: 1600px;
}

.footer .wrapper {
  padding-inline: 50px;
  overflow-x: hidden;
}

.footer marquee {
  font-size: 60px;
  font-weight: 800;
  color: #fff;
  padding: 30px 0 40px;
}

.footer .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 50px;
  margin-bottom: 70px;
  border-bottom: 1px solid #fff;
  column-gap: 70px;
}

.footer .desc {
  font-size: 32px;
  color: #fff;
  padding-bottom: 30px;
  border-bottom: 1px solid #fff;
  margin-bottom: 30px;
  font-weight: 700;
}

.footer .shortDesc {
  font-size: 25px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 30px;
}

.footer .leftWrapper img {
  max-width: 100%;
  width: 100%;
}

.footer .rightWrapper a {
  background: rgba(0, 0, 0, 0.25);
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  border-radius: 6px;
  border: 1px solid #000;
  padding: 8px 24px;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
}

.footer .grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 24px;
  padding-bottom: 80px;
  border-bottom: 1px solid #fff;
}

.footer .grid-3 .card {
  background-color: #fff;
  border-radius: 36px;
  padding: 30px 30px 60px;
  position: relative;
}

.footer .grid-3 .card::before {
  content: "";
  position: absolute;
  background: rgba(255, 255, 255, 0.25);
  height: 50px;
  width: 100%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  top: -20px;
  left: 0;
}

.footer .grid-3 .card svg {
  max-width: 120px;
}

.footer .grid-3 .card .about {
  max-width: 350px;
  font-size: 20px;
  color: #000;
  padding-top: 20px;
}

.footer .grid-3 .card .heading {
  font-size: 30px;
  font-weight: 800;
  color: #000;
  padding-bottom: 30px;
}

.footer .card .socialWrapper a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
}

.footer .card .socialWrapper a:hover {
  background-color: #eee;
}

.footer .card .socialWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.footer .card .socialWrapper img {
  width: 30px;
  height: 30px;
}

.footer .card .location {
  font-size: 22px;
  font-weight: 800;
  padding-bottom: 10px;
  color: var(--pink);
}

.footer .card .address {
  font-size: 18px;
  max-width: 350px;
}

.footer .copyright {
  text-align: center;
  padding: 20px 0px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.partners {
  padding-top: 130px;
}

.partners .heading {
  text-align: center;
  padding-bottom: 60px;
}

.partnerWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  padding-bottom: 130px;
}

.partnerWrapper .box {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  transition: all 0.2s ease-in-out;
}

.partnerWrapper a:nth-child(8n + 2),
.partnerWrapper a:nth-child(8n + 4),
.partnerWrapper a:nth-child(8n + 5),
.partnerWrapper a:nth-child(8n + 7) {
  background-color: rgba(255, 255, 255, 0.1);
}

.partnerWrapper .box:hover {
  background-color: #fff !important;
}

.partnerWrapper .box img {
  filter: brightness(0%) invert(1);
  max-width: 80%;
  transition: all 0.2s ease-in-out;
}

.partnerWrapper .box:hover img {
  filter: none;
}

.hero {
  padding:10px 0 50px 0;
  background: url("./assets/images/landing.png") no-repeat center;
  background-size: cover;
}

.hero .container {
  position: relative;
}

.hero .blueCircle {
  top: -100px;
  right: -100px;
}

.hero .heading {
  color: #fff;
  font-size: 60px;
  text-align: center;
  max-width: 800px;
  margin-inline: auto;
  font-weight: 800;
  margin-top: 40px;
}

.hero .heading span {
  color: var(--pink);
}

.hero .subHeading {
  color: #fff;
  font-size: 23px;
  max-width: 700px;
  margin-inline: auto;
  text-align: center;
  padding: 30px 0px;
}

.hero .join {
  border-radius: 6px;
  border: 1px solid #002492;
  background: linear-gradient(
    0deg,
    rgba(0, 36, 146, 0.25) -14.44%,
    rgba(225, 4, 149, 0.25) 113.97%
  );
  color: #fff;
  display: block;
  margin-inline: auto;
  max-width: 250px;
  text-transform: uppercase;
  box-shadow: 4px 4px 6px 0px rgba(0, 36, 146, 0.5);
  text-align: center;
  padding: 16px;
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 50px;
}

.heroBottom {
  display: flex;
  justify-content: space-between;
  margin-top: -350px;
  position: relative;
}

.heroBottom .pinkCircle {
  left: -100px;
}

.heroBottom .heroImg {
  position: relative;
  padding-top: 50px;
}

.heroBottom .heroImg img {
  max-width: 90%;
  padding-top: 300px;
}

.heroBottom .text {
  font-size: 26px;
  max-width: 220px;
  color: #fff;
  line-height: 45px;
  font-weight: 300;
  padding-top: 150px;
  position: relative;
}

.heroBottom .socialMedia {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.heroBottom .socialMedia a {
  text-decoration: none;
  color: #fff;
  text-align: right;
  font-size: 18px;
  transition: all 0.2s ease-in-out;
}

.heroBottom .socialMedia a:hover {
  color: #cf2772;
}

.whyChoose {
  position: relative;
  padding-top: 150px;
}

.whyChoose .gridWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 80px;
}

.whyChoose .leftWrapper {
  position: relative;
}

.whyChoose .heading,
.howItWorks .heading {
  color: #fff;
  font-size: 45px;
  font-weight: 800;
}

.whyChoose .myArbit {
  height: 25px;
  width: 200px;
}

.whyChoose .subHeading {
  color: var(--pink);
  font-size: 35px;
  font-weight: 800;
  max-width: 500px;
  padding-top: 20px;
}

.whyChoose .desc {
  color: #fff;
  font-size: 22px;
  padding-top: 20px;
  line-height: 35px;
  position: relative;
}

.whyChoose .blueCircle {
  right: -150px;
  z-index: 0;
  top: -150px;
}

.whyChoose .bg {
  position: absolute;
  left: -100px;
  height: 550px;
  top: 0;
}

.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.features {
  padding-top: 130px;
}

.features .row {
  overflow-x: hidden;
}

.descBox {
  width: 50%;
  flex: 0 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
}

.hero .rightCoin {
  position: absolute;
  right: 0;
}

.hero .leftCoin {
  position: absolute;
  left: 0;
}

.hero .rightCoin img,
.hero .leftCoin img {
  width: 100%;
  height: 300px;
}

.features .icon-box {
  width: 25%;
  flex: 0 0 auto;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  height: 290px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}

.icon-box.investment:hover {
  background: rgba(225, 4, 149, 0.15);
}

.icon-box.transaction:hover {
  background: rgba(6, 194, 137, 0.15);
}

.icon-box.flexible:hover {
  background: rgba(77, 107, 196, 0.15);
}

.icon-box.easy:hover {
  background: rgba(189, 0, 255, 0.15);
}

.icon-box.profitiers:hover {
  background: rgba(126, 151, 245, 0.15);
}

.icon-box.reward:hover {
  background: rgba(216, 95, 174, 0.15);
}

.features .heading svg {
  width: 220px;
}

.features .heading {
  color: #fff;
  font-size: 45px;
  font-weight: 800;
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.features .icon-box svg {
  width: 60px;
  height: 60px;
  margin-inline: auto;
}

.features .desc {
  font-size: 20px;
  color: #fff;
  line-height: 30px;
  padding-top: 15px;
  max-width: 90%;
}

.features .icon-box .subHeading {
  font-size: 20px;
  padding-top: 15px;
  color: #fff;
  font-weight: 500;
  max-width: 120px;
  margin-inline: auto;
}

.row.second .icon-box {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.row.second .icon-box.bl-0 {
  border-left: none;
}

.howItWorks {
  padding-top: 100px;
}

.howItWorks .subHeading {
  font-size: 22px;
  color: #fff;
  padding-top: 20px;
}

.howItWorks .cardWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  padding: 50px 0px 0px;
  position: relative;
}

.howItWorks .pinkCircle {
  right: -150px;
  bottom: -50px;
}

.howItWorks .cardWrapper .card {
  padding: 30px;
  border-radius: 24px;
  position: relative;
  z-index: 1;
  transition: all 0.2s ease-in-out;
}

.howItWorks .cardWrapper .card .number {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  font-size: 25px;
  font-weight: 800;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.howItWorks .cardWrapper .card-1:hover {
  background-color: rgba(225, 4, 149, 0.35) !important;
}

.howItWorks .cardWrapper .card-2:hover {
  background-color: rgba(255, 255, 255, 0.35) !important;
}

.howItWorks .cardWrapper .card-3:hover {
  background-color: rgba(102, 144, 255, 0.35) !important;
}

.howItWorks .cardWrapper .card-4:hover {
  background-color: rgba(6, 194, 137, 0.35) !important;
}

.howItWorks .cardWrapper .card-1:hover .number {
  background-color: var(--pink);
  color: #fff !important;
  border-color: var(--pink);
}

.howItWorks .cardWrapper .card-2:hover .number {
  background-color: #fff;
  color: var(--navyBlue) !important;
  border-color: #fff;
}

.howItWorks .cardWrapper .card-3:hover .number {
  background-color: #6690ff;
  color: #fff !important;
  border-color: #6690ff;
}

.howItWorks .cardWrapper .card-4:hover .number {
  background-color: #06c289;
  color: #fff !important;
  border-color: #06c289;
}

.howItWorks .cardWrapper .card .title {
  font-size: 35px;
  font-weight: 800;
  padding-top: 20px;
}

.howItWorks .cardWrapper .card .desc {
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  padding-top: 15px;
}

.crypto {
  padding-top: 130px;
}

.crypto .heading {
  font-size: 60px;
  color: #fff;
  font-weight: 800;
  max-width: 70%;
  padding-bottom: 50px;
}

.crypto .heading .blue {
  color: var(--blue);
}

.crypto .heading .pink {
  color: var(--pink);
}

.crypto .gridWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-inline: 50px;
  column-gap: 50px;
  padding-bottom: 130px;
  position: relative;
}

.crypto .gridWrapper .box {
  position: relative;
}

.crypto .gridWrapper .box img {
  max-width: 100%;
  position: relative;
  z-index: 1;
}

.crypto .gridWrapper .blueCircle {
  right: -150px;
  bottom: -150px;
}

.crypto .gridWrapper .box::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  top: 10px;
  left: -10px;
}

.crypto .gridWrapper .leftWrapper .box::before {
  border: 1px solid var(--pink);
  box-shadow: -10px 10px 10px 0px rgba(225, 4, 149, 0.25);
  background: rgba(225, 4, 149, 0.15);
}

.crypto .gridWrapper .rightWrapper .box::before {
  border: 1px solid var(--blue);
  background: rgba(0, 53, 255, 0.25);
  box-shadow: -10px 10px 10px 0px rgba(0, 53, 255, 0.25);
}

.crypto .leftHeading {
  font-size: 40px;
  color: #fff;
  font-weight: 800;
  max-width: 37%;
  text-align: right;
}

.crypto .rightHeading {
  font-size: 40px;
  color: #fff;
  font-weight: 800;
  max-width: 55%;
  margin-left: auto;
  text-align: left;
  padding-top: 100px;
  padding-right: 50px;
}

.crypto .rightHeading a {
  color: #fff;
  display: inline-block;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  border-radius: 6px;
  padding: 8px 24px;
  border: 1px solid var(--pink);
  background: rgba(225, 4, 149, 0.25);
  box-shadow: 4px 4px 4px 0px rgba(225, 4, 149, 0.25);
}

.crypto .cryptoBottom {
  position: relative;
  overflow: hidden;
}

.crypto .cryptoBottom svg {
  width: 450px;
  height: 270px;
  position: absolute;
  left: 35%;
  transform: translateX(-35%);
  top: 50px;
}

.crypto .cryptoBottom svg.mdNone {
  display: none;
}

.blogs {
  padding-top: 130px;
  position: relative;
}

.blogs .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--pink);
  font-size: 50px;
  font-weight: 800;
  position: relative;
}

.blogs .heading .icon,
.blogs .heading .icon a {
  background-color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  transition: all 0.2s ease-in-out;
}

.blogs .heading .icon svg {
  width: 40px;
  height: 40px;
}

.blogs .heading .icon:hover a {
  background-color: var(--pink);
}

.blogs .heading .icon:hover svg image {
  filter: invert(1);
}

.blogs .subHeading {
  color: #fff;
  font-size: 30px;
  font-weight: 800;
  position: relative;
}

.blogs .desc {
  color: #fff;
  padding-top: 15px;
  font-size: 20px;
  max-width: 70%;
  position: relative;
}

.blogs .gridWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 80%;
  gap: 30px;
  padding-top: 50px;
}

.blogs .gridWrapper .blog {
  background-color: rgba(54, 36, 101, 0.5);
  border-radius: 24px;
  padding: 20px;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.blogs .gridWrapper .blog:hover {
  border: 1px solid #fff;
}

.blogs .gridWrapper .blog .featuredImg {
  max-width: 100%;
  border-radius: 24px;
}

.blogs .blog .postHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}

.blogs .blog .postHead span {
  color: #fff;
  font-size: 16px;
}

.blogs .blog .nameWrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 16px;
  color: #fff;
  font-weight: 800;
}

.blogs .blog .nameWrapper img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.blogs .blog .title {
  color: #fff;
  font-size: 25px;
  font-weight: 800;
  padding-top: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.blogs .blog .shortDesc {
  color: #fff;
  font-size: 18px;
  padding-top: 15px;
  margin-bottom: 50px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.blogs .blog .link {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  padding: 8px 24px;
  border-radius: 6px;
  background: rgba(225, 4, 149, 0.25);
  display: inline-block;
  border: 1px solid var(--pink);
  box-shadow: 4px 4px 4px 0px rgba(225, 4, 149, 0.25);
}

.benefits {
  padding-top: 130px;
  position: relative;
}

.benefits .rightImg {
  top: 130px;
  position: absolute;
  right: -60px;
}

.benefits .rightImg img {
  max-width: 100%;
  max-height: 500px;
}

.benefits .pinkCircle {
  left: -150px;
  top: -150px;
}

.benefits .heading {
  color: var(--pink);
  font-size: 50px;
  font-weight: 800;
  position: relative;
}

.benefits .subHeading {
  color: #fff;
  font-size: 30px;
  font-weight: 800;
  max-width: 450px;
  padding-top: 15px;
  position: relative;
}

.benefits .desc {
  font-size: 18px;
  color: #fff;
  line-height: 27px;
  padding-top: 15px;
  max-width: 550px;
  padding-bottom: 50px;
  position: relative;
}

.benefits .benefitWrapper {
  display: flex;
  column-gap: 30px;
  padding-bottom: 70px;
  position: relative;
}

.benefits .benefitWrapper .number {
  background: linear-gradient(0deg, #002492 -14.44%, #e10495 113.97%);
  max-width: 50px;
  min-width: 50px;
  height: 50px;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.benefits .benefitWrapper:not(:last-child) .number::after {
  content: "";
  position: absolute;
  top: 50px;
  height: 100%;
  width: 1px;
  background-color: #fff;
}

.benefits .benefitWrapper .rightWrapper .title {
  font-size: 35px;
  color: #fff;
  font-weight: 800;
  max-width: 450px;
}

.benefits .benefitWrapper .rightWrapper .shortDesc {
  font-size: 18px;
  color: #fff;
  max-width: 500px;
  padding-top: 15px;
}

.benefits .cardWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  max-width: 900px;
  padding-top: 30px;
}

.benefits .cardWrapper .card {
  background-color: #fff;
  border-radius: 24px;
  padding: 20px;
  position: relative;
  z-index: 1;
}

.benefits .cardWrapper .card .cardTitle {
  color: #000;
  font-size: 25px;
  font-weight: 800;
  line-height: 1.3em;
}

.benefitBottom .iconBox {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #0c0c31;
  display: flex;
  align-items: center;
  padding: 30px;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -60px;
}

.benefitBottom {
  border-radius: 48px;
  border: 1px solid var(--blue);
  background: linear-gradient(
    0deg,
    rgba(0, 36, 146, 0.25) -14.44%,
    rgba(225, 4, 149, 0.25) 113.97%
  );
  position: relative;
  max-width: 1100px;
  margin-inline: auto;
  transition: all 0.2s ease-in-out;
  margin-top: 130px;
}

.benefitBottom:hover {
  box-shadow: 10px 10px 10px 0px rgba(0, 53, 255, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.benefitBottom .wrapper {
  max-width: 800px;
  margin-inline: auto;
  display: flex;
  align-items: center;
  padding: 100px 0px;
  position: relative;
}

.benefitBottom .flex-1 {
  flex: 1;
}

.benefitBottom .wrapper .heading {
  color: #fff;
  font-size: 45px;
  text-align: center;
  font-weight: 800;
}

.benefitBottom .wrapper .subHeading {
  color: var(--pink);
  font-size: 30px;
  text-align: center;
  font-weight: 800;
  padding-top: 10px;
}

.benefitBottom .wrapper svg {
  height: 270px;
}

.benefitBottom .wrapper .desc {
  color: #fff;
  font-size: 20px;
  text-align: center;
  padding-top: 20px;
  max-width: 550px;
  margin-inline: auto;
}

.pinkCircle {
  background: rgba(225, 4, 149, 0.48);
}

.blueCircle {
  background: rgba(0, 36, 146, 0.48);
}

.circle {
  width: 500px;
  height: 500px;
  border-radius: 50%;
  filter: blur(200px);
  position: absolute;
}

.benefitBottom .pinkCircle {
  right: -150px;
  top: -150px;
}

.blogs .blueCircle {
  left: -150px;
  top: -150px;
}

.menuBtn {
  height: 40px;
  width: 40px;
  position: relative;
  z-index: 3;
  display: none;
}

.menuBtn::before,
.menuBtn::after {
  content: "";
  width: 100%;
  height: 2px;
  left: 0;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  -webkit-transition: -webkit-transform 0.5s 0.75s;
  transition: -webkit-transform 0.5s 0.75s;
  -o-transition: transform 0.5s 0.75s;
  transition: transform 0.5s 0.75s;
  transition: transform 0.5s 0.75s, -webkit-transform 0.5s 0.75s;
  background-color: #fff;
  border-radius: 2px;
}

.menuBtn::before {
  -webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
}

.menuBtn::after {
  -webkit-transform: translateY(4px);
  -ms-transform: translateY(4px);
  transform: translateY(4px);
}

.menuActive .menuBtn::before,
.menuActive .menuBtn::after {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.menu {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: #fff;
  -webkit-transition: all 0.5s 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
  -o-transition: all 0.5s 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
  transition: all 0.5s 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
  visibility: hidden;
  -webkit-clip-path: inset(0 0 0 100%);
  clip-path: inset(0 0 0 100%);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

.menuActive .menu {
  visibility: visible;
  -webkit-clip-path: inset(0 0 0 0);
  clip-path: inset(0 0 0 0);
}

.menuClose {
  position: relative;
  width: 30px;
  height: 30px;
}

.menuClose::before,
.menuClose::after {
  content: "";
  width: 100%;
  border-radius: 2px;
  background-color: #000;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  -o-transition: transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.menuActive .menuClose::before,
.menuActive .menuClose::after {
  -webkit-transition-delay: 0.75s;
  -o-transition-delay: 0.75s;
  transition-delay: 0.75s;
}

.menuActive .menuClose::before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menuActive .menuClose::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.heroSection {
  background: url("assets/images/heroBg.png") no-repeat center;
  padding: 130px 0px;
  background-size: cover;
}

.heroSection .heading {
  color: #fff;
  font-size: 60px;
  font-weight: 800;
  text-align: center;
}

.heroSection .subHeading {
  color: #fff;
  font-size: 28px;
  text-align: center;
  max-width: 900px;
  margin-inline: auto;
  padding-top: 20px;
}

.aboutUs .gridWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  padding-top: 80px;
  align-items: center;
  overflow: hidden;
}

.aboutUs .gridWrapper .leftWrapper .heading {
  font-size: 50px;
  color: #fff;
  font-weight: 800;
}

.aboutUs .gridWrapper .leftWrapper .desc {
  font-size: 22px;
  line-height: 35px;
  max-width: 95%;
  color: #fff;
  padding-top: 20px;
}

.aboutUs .gridWrapper .rightWrapper img {
  width: 100%;
}

.aboutUs .content {
  max-width: 1100px;
  margin-inline: auto;
  text-align: center;
  padding-top: 100px;
}

.aboutUs .container {
  position: relative;
}

.aboutUs .container .pinkCircle {
  left: -100px;
}

.aboutUs .container .blueCircle {
  right: -100px;
}

.aboutUs .content .heading {
  padding-top: 40px;
}

.aboutUs .content .desc {
  max-width: 80%;
  margin-inline: auto;
  color: #fff;
  font-size: 22px;
  padding-top: 20px;
}

.aboutUs .bitcoin {
  width: 600px;
  max-width: 100%;
  padding: 50px 0px;
}

.aboutUs .content .subHeading {
  color: #fff;
  font-size: 40px;
  color: #fff;
  font-weight: 800;
  max-width: 87%;
  margin-inline: auto;
  padding-top: 80px;
}

.aboutUs .content .subHeading .pink {
  color: var(--pink);
}

.aboutUs .content .subHeading .blue {
  color: var(--blue);
}

.aboutUs .register {
  border-radius: 6px;
  background: rgba(225, 4, 149, 0.25);
  color: #fff;
  display: inline-block;
  padding: 10px 30px;
  font-size: 20px;
  border: 1px solid var(--pink);
  text-decoration: none;
  margin-top: 20px;
  box-shadow: 4px 4px 4px 0px rgba(225, 4, 149, 0.25);
}

.contactUs {
  padding-top: 100px;
}

.contactUs .gridWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 80px;
  background: url("./assets/images/contact.png") no-repeat center;
  background-size: cover;
}

.heading {
  color: #fff;
  font-size: 50px;
  font-weight: 800;
  position: relative;
}

.contactUs .leftWrapper .subHeading {
  font-size: 24px;
  color: #fff;
  padding-top: 20px;
  max-width: 500px;
  position: relative;
}

.contactUs .infoBox {
  margin: 30px 0 0 30px;
  padding: 40px 0px 40px 30px;
  border-left: 10px solid var(--pink);
}

.contactUs .infoBox .label {
  color: #b4b4b4;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 5px;
  position: relative;
}

.contactUs .infoBox .info {
  padding-bottom: 50px;
  position: relative;
}

.contactUs .infoBox .info a {
  color: #fff;
  text-decoration: none;
  font-size: 24px;
  max-width: 80%;
}

.contactUs .infoBox .info.pb-0 {
  padding-bottom: 0;
}

.contactUs .rightWrapper {
  background-color: #fff;
  padding: 50px 30px;
  position: relative;
}

.contactUs .rightWrapper .heading {
  color: var(--pink);
  font-size: 30px;
  font-weight: 800;
}

.contactUs .rightWrapper .subHeading {
  color: #000;
  font-size: 18px;
  padding: 10px 0px 15px 0px;
}

.contactUs .rightWrapper .label {
  color: #b4b4b4;
  font-size: 16px;
  font-weight: 600;
  padding-top: 15px;
}

.contactUs .rightWrapper input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5) !important;
  border: none;
  border-radius: 0;
  width: 100%;
  padding: 10px 0px;
  color: #000;
  outline: none;
  font-size: 18px;
  margin-bottom: 5px;
}

.contactUs .rightWrapper .label.message {
  color: #000;
  padding-bottom: 10px;
}

.contactUs .rightWrapper textarea {
  width: 100%;
  outline: none;
  resize: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  font-weight: 500;
  padding: 10px;
  font-size: 18px;
  color: #000;
  font-family: "Manrope", sans-serif;
}

.contactUs .rightWrapper .submitBtn {
  border: 1px solid transparent;
  border-bottom: 1px solid transparent !important;
  margin-top: 20px;
  color: #fff;
  background-color: var(--pink);
  display: inline-block;
  padding: 10px 30px;
  margin-bottom: 15px;
  width: auto;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.contactUs .rightWrapper .submitBtn:hover {
  background-color: transparent;
  border-bottom: 1px solid var(--pink) !important;
  border: 1px solid var(--pink);
  color: var(--pink);
}

.error {
  color: #dd3333;
  font-size: 14px;
  font-weight: 500;
}

.faq {
  padding-top: 80px;
}

.faq .subHeading {
  font-size: 28px;
  color: #fff;
  padding-top: 10px;
  position: relative;
}

.faq-row-wrapper {
  background-color: transparent !important;
  padding-top: 50px;
}

.row-title {
  color: #fff !important;
  font-size: 24px !important;
  font-weight: 800;
  transition: all 0.2s ease-in-out;
}

.row-title-text {
  flex: 1;
  padding-right: 1em !important;
}

.row-title:hover {
  color: var(--pink) !important;
}

.faq-row {
  padding: 10px 0 !important;
}

.arrow-image svg {
  fill: #fff !important;
}

.row-content-text {
  color: #fff !important;
  font-size: 18px !important;
  padding-bottom: 30px !important;
}

.icon-wrapper {
  position: relative !important;
  top: auto !important;
}

.contactUs .pinkCircle,
.faq .pinkCircle {
  left: -100px;
}

.blogPage {
  padding-top: 80px;
}

.blogPage .heading {
  color: #fff;
}

.blogPage .gridWrapper {
  grid-template-columns: repeat(3, 1fr);
  max-width: 100%;
}

.profit {
  padding: 80px 0px;
  position: relative;
}

.profit .row {
  flex-wrap: nowrap;
}

.profit .heading {
  text-align: center;
  max-width: 850px;
  margin-inline: auto;
  padding-bottom: 70px;
}

.profit .pinkCircle {
  left: -100px;
}

.profit .column .title {
  color: var(--pink);
  font-size: 32px;
  font-weight: 800;
  padding-bottom: 10px;
}

.profit .name {
  color: #fff;
  font-size: 50px;
  font-weight: 800;
  max-width: 100px;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}

.profit .row:not(:last-of-type) .name::after {
  content: "";
  position: absolute;
  top: 100%;
  height: 300px;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  background: var(--pink);
}

.profit .col-md-5 {
  width: 50%;
}

.profit .col-md-2 {
  width: 20%;
  display: flex;
  justify-content: center;
}

.profit .row.last .name::after {
  display: none;
}

.profit .column {
  overflow-y: hidden;
}

.profit .table {
  background-color: #fff;
  border-radius: 24px;
  padding: 30px;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.profit .table:hover {
  box-shadow: 0px 0px 25px 0px #e10495;
}

.profit .table .tableHead {
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  font-size: 18px;
  font-weight: 700;
  color: var(--navyBlue);
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 36, 146, 0.5);
}

.profit .table .tableBody {
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  font-size: 16px;
  color: var(--navyBlue);
  padding: 15px 0px;
  border-bottom: 1px solid rgba(0, 36, 146, 0.5);
}

.profit .table .tableBody .package {
  font-weight: 600;
}

.profit .table .tableHead > div,
.profit .table .tableBody > div {
  flex: 1;
}

.blogDetail {
  padding-top: 80px;
}

.blogDetail .container {
  position: relative;
}

.blogDetail .featuredImg {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  border-radius: 20px;
  position: relative;
}

.blogDetail .pinkCircle {
  left: -100px;
  top: -100px;
}

.blogDetail .postHead {
  display: flex;
  align-items: center;
  column-gap: 20px;
  position: relative;
  padding-top: 20px;
  color: var(--pink);

}

.blogDetail .postHead .nameWrapper {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.blogDetail .postHead .nameWrapper .name {
  font-size: 22px;
  color: #fff;
  font-weight: 800;
}

.blogDetail .postHead .date {
  font-size: 18px;
  color: #fff;
}

.blogDetail .content {
  padding-top: 30px;
}

.blogDetail .content .mainHeading {
  color: #fff;
  font-weight: 800;
  padding-bottom: 30px;
  font-size: 50px;
}
.blogDetail .content .subMainHeading {
  color: #fff;
  font-weight: 700;
  padding-bottom: 30px;
  font-size: 45px;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.blogDetail .content .heading {
  font-size: 30px;
}

.blogDetail .content p {
  color: #fff;
  font-size: 20px;
  padding: 10px 0px 40px 0px;
}

.blogDetail .content .pinkTitle {
  color: var(--pink);
  font-size: 28px;
  font-weight: 800;
}

.blogDetail .authorBox {
  background: linear-gradient(
    0deg,
    rgba(0, 36, 146, 0.25) -14.44%,
    rgba(225, 4, 149, 0.25) 113.97%
  );
  border-radius: 36px;
  padding: 30px;
  max-width: 80%;
  margin-bottom: 30px;
  border: 1px solid var(--navyBlue);
}

.blogDetail .authorBox .author {
  position: relative;
  color: var(--pink);
  font-size: 22px;
  font-weight: 800;
  padding-left: 40px;
}

.blogDetail .authorBox .author::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 30px;
  height: 3px;
  background: var(--pink);
}

.blogDetail .authorBox p {
  font-size: 28px;
  padding-bottom: 20px;
}

.blogDetail .content .gridWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.blogDetail .content .gridWrapper p {
  max-width: 80%;
}

.transitionWrapper {
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  z-index: 2;
  top: 0;
  left: 0;
  background: #0c0c31;
}

.hero .btnWrapper {
  display: none;
}

.leftCoinMbl {
  display: none;
}

.success {
  color: green;
  font-weight: 500;
}

.row-reverse {
  flex-direction: row-reverse;
}

.see-more-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.see-more {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  padding: 16px 32px;
  border-radius: 6px;
  background: rgba(225, 4, 149, 0.25);
  display: inline-block;
  border: 1px solid var(--pink);
  box-shadow: 4px 4px 4px 0px rgba(225, 4, 149, 0.25);
}
.content {
  /* General styling for the content area */
}

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  color: white;
}

.content strong {
  font-weight: bold;
}

.content a {
  color: #e10495;
  text-decoration: none; 
}

.content a:hover {
  text-decoration: underline; 
}

.content ol li,
.content ul li {
  margin-left: 40px;
  list-style-type: disc;
  font-size: 30px;
  line-height: 2; /* Increase line height for more space between text lines */
}

.content ol li span,
.content ul li span {
  font-weight: bold;
  color: #e10495; 
}

.content ol li,
.content ul li {
  color: white; 
  font-weight: 400;
  font-size: 18px;
  margin-left: 10px;
  color: #fff !important;
}


 .tabset {
  display: flex;
  color: #e10495;
  justify-content: center;
  overflow: hidden;
  gap: 10px;
  margin-bottom: 90px; /* Adds space between tabs */
}

.tabset > input[type="radio"] {
  position: absolute;
  left: -200vw;
}

.tabset .tab-panel {
  display: none;
}

.tabset > label {
  display: inline-flex;
  align-items: center; /* Vertically center text */
  justify-content: center; /* Horizontally center text */
  padding: 17px 48px;
  border: 1px solid transparent;
  border-bottom: 0;
  cursor: pointer;
  font-weight: 600;
  border-radius: 25px; /* Fully rounded tabs */
  background-color: #f3f3f3; /* Light background for default state */
  margin: 0;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  text-align: center; /* Ensures the text is centered */
  min-width: 100px; /* Optional: Sets a minimum width for tabs */
}

.tabset > label:hover {
  background-color: #dcdcdc; /* Slightly darker on hover */
  color: #e10495; /* Highlight text on hover */
}

.tabset > input:checked + label {
  border-color: #ccc;
  background-color: #e10495; /* Active tab background */
  color: white; /* Active tab text color */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Adds a shadow for depth */
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

.tabset > label::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  width: 22px;
  height: 4px;
  background: #8d8d8d;
  border-radius: 2px;
}

.tabset > input:checked + label::after {
  background: #ffffff; /* Change the color of the underline for active tab */
}





/* Add this in your CSS file */
.styled-text {
  font-size: 1.2rem;  /* Increase font size */
  color: white;        /* Text color */
  line-height: 1.6;   /* Increase line spacing */
  margin-top: 20px;   /* Add spacing above */
  padding: 10px;      /* Add some padding */
  border-left: 4px solid #e10495; /* Add a left border for a highlight effect */
  border-radius: 5px; 
  margin-bottom: 40px;/* Add slight border radius */
}


.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  color: white;
}
.content strong {
  font-weight: bold;
}
.content ol {
  color: #e10495;
  font-size: 28px;
  font-weight: 800;
}
.content ol li {
  margin-left: 20px; 
  font-size: 28px;
  font-weight: 800;
}
.content ul {
  color: #e10495;
  font-size: 28px;
  font-weight: 800;
}
.content ul li {
  margin-left: 20px; 
  list-style-type: disc; 
  font-weight: bold;
  font-size: 28px;
  font-weight: 800;
}
.content a {
  color: #e10495;
  text-decoration: none; 
}
.content a:hover {
  text-decoration: underline; 
}
.date-blog{
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 768px) {
  .tabset {
    flex-direction: column;
    gap: 5px;
    margin-bottom: 50px;
  }

  .tabset > label {
    padding: 12px 20px;
    font-size: 14px;
    min-width: unset;
  }

  .tabset > input:checked + label::after {
    width: 16px;
  }
}


.content ul li em strong {
  font-size: 20px;    /* Larger font size for the heading */
  font-weight: 700;   /* Bold font */
  color: #e10495;     /* Color for bullet point headings */
}

/* Smaller text for the content under bullet points */
.content ul li {
  font-size: 1rem;    /* Smaller font size for descriptions */
  font-weight: normal;/* Normal font weight for content */
  color: white;       /* Text color */
  margin-left: 20px;  /* Indent the content for clarity */
  display: block;  /* Line height for readability */
}

/* Ensure list itself is styled properly */
.content ul, .content ol {
 
  list-style-position: inside; /* Bullet or number inside the text area */
}








.form-heading {
  margin-top: 30px;
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
  color: #fff;
}
.form-container {
  max-width: 450px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  border-radius: 20px;
  margin-top: 20px;

}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.otp-container {
  display: flex;
  gap: 15px;
}

.send-otp-btn {
  padding: 6px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  white-space: np;
}

.send-otp-btn:hover {
  background-color: #0056b3;
}

.submit-btn {
  width: 100%;
  padding: 10px;
  background-color: #e10495;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #c0037d;
}


@media only screen and (max-width: 568px) {
  .form-container {
  margin: 25px;
  
  }

}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  text-align: center;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.yes-btn, .no-btn {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.yes-btn {
  background-color: #e10495;
  color: white;
}

.no-btn {
  background-color: #ddd;
}