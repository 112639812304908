@media only screen and (max-width: 768px) {
  .container {
    padding-inline: 20px;
  }
  .navbar {
    display: none;
  }
  .menu .navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding-top: 30px;
    gap: 15px;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    -o-transition-property: transform, opacity;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transition-duration: 0.25s;
    -o-transition-duration: 0.25s;
    transition-duration: 0.25s;
    opacity: 0;
    transform: translateX(100vw);
  }
  .menuActive .menu .navbar {
    opacity: 1;
    transform: translateX(0);
    transition-delay: 1s;
  }
  .header .wrapper .btnWrapper {
    display: none;
  }
  .btnWrapper {
    padding: 30px 0px;
    justify-content: flex-start;
  }
  .navbar a.active {
    color: var(--pink);
    border-color: transparent;
    font-weight: 700;
  }

  .menuBtn {
    display: block;
  }

  .footer .wrapper {
    padding-inline: 20px;
  }

  .footer .grid {
    grid-template-columns: auto;
    gap: 30px;
  }

  .footer .grid-3 {
    grid-template-columns: auto;
    gap: 50px;
  }

  .footer .grid-3 .card .about {
    max-width: 100%;
  }

  .blogPage {
    padding-top: 50px;
  }

  .blogPage .gridWrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .heroSection .heading {
    font-size: 50px;
  }

  .navbar a {
    font-size: 24px;
    color: var(--navyBlue);
  }

  .footer marquee {
    font-size: 50px;
  }

  .footer .leftWrapper img {
    border-radius: 15px;
    height: 300px;
    object-fit: cover;
  }

  .blogs .gridWrapper .blog {
    padding: 15px;
  }

  .heroSection .subHeading {
    font-size: 22px;
  }

  .blogs .heading {
    font-size: 40px;
  }

  .blogs .blog .title {
    font-size: 20px;
  }

  .menu .socialMedia {
    border-top: 1px solid rgba(0, 0, 0, 0.16);
    padding: 30px 0px;
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .menu .socialMedia a {
    color: #000;
    text-decoration: none;
  }

  .menu .socialMedia a:hover {
    color: var(--pink);
  }

  .howItWorks .cardWrapper {
    grid-template-columns: auto;
  }

  .partnerWrapper {
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 80px;
  }

  .whyChoose .gridWrapper {
    grid-template-columns: auto;
  }

  .benefitBottom {
    margin-inline: 20px;
  }

  .whyChoose .bg {
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
  }

  .circle {
    width: 300px;
    height: 300px;
  }

  .header .wrapper {
    height: 80px;
  }

  .hero .blueCircle,
  .howItWorks .pinkCircle,
  .crypto .gridWrapper .blueCircle,
  .benefits .rightImg,
  .benefitBottom .pinkCircle,
  .whyChoose .blueCircle,
  .aboutUs .container .blueCircle {
    right: 0;
  }

  .heroBottom {
    flex-direction: column;
  }

  .blogs .gridWrapper {
    max-width: 100%;
    gap: 20px;
  }

  .heroBottom .text {
    display: none;
  }

  .heroBottom .socialMedia {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-top: 30px;
    position: relative;
    z-index: 2;
  }

  .heroBottom .heroImg {
    text-align: center;
  }

  .heroBottom .heroImg img {
    max-width: 80%;
  }

  .crypto .heading {
    font-size: 50px;
    max-width: 100%;
  }

  .heroBottom .socialMedia a {
    text-align: center;
  }

  .crypto .rightHeading {
    font-size: 30px;
    padding-right: 0;
  }

  .crypto .leftHeading {
    font-size: 30px;
  }

  .crypto .cryptoBottom svg {
    width: 350px;
    height: 200px;
  }

  .crypto .rightHeading a {
    margin-top: 15px;
  }

  .partnerWrapper .box {
    height: 250px;
  }

  .partnerWrapper a:nth-child(8n + 1),
  .partnerWrapper a:nth-child(8n + 3),
  .partnerWrapper a:nth-child(8n + 5),
  .partnerWrapper a:nth-child(8n + 7) {
    background-color: transparent;
  }

  .partnerWrapper a:nth-child(8n + 2),
  .partnerWrapper a:nth-child(8n + 4),
  .partnerWrapper a:nth-child(8n + 6),
  .partnerWrapper a:nth-child(8n + 8) {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .partners {
    padding-top: 80px;
  }

  .partnerWrapper .box img {
    max-height: 130px;
  }

  .partners .heading {
    font-size: 40px;
  }

  .heading {
    font-size: 40px;
  }

  .faq .subHeading {
    font-size: 22px;
  }

  .row-title {
    font-size: 20px !important;
  }

  .aboutUs .gridWrapper {
    grid-template-columns: auto;
  }

  .aboutUs .gridWrapper .leftWrapper .heading {
    font-size: 40px;
  }

  .aboutUs .content .desc {
    max-width: 90%;
  }

  .aboutUs .content {
    padding-top: 40px;
  }

  .blogs .blog .nameWrapper img {
    width: 40px;
    height: 40px;
  }

  .blogs .blog .shortDesc {
    font-size: 16px;
    margin-bottom: 40px;
  }

  .blogDetail .content .mainHeading {
    font-size: 36px;
  }
  .contactUs .infoBox {
    margin-left: 0;
  }
  .contactUs .leftWrapper .subHeading {
    font-size: 20px;
  }
  .contactUs .rightWrapper {
    padding: 30px 20px;
  }

  .contactUs .infoBox .info a {
    font-size: 20px;
  }

  .benefits .rightImg img {
    max-height: 300px;
  }

  .benefits .cardWrapper {
    gap: 20px;
  }

  .benefits .cardWrapper .card .cardTitle {
    font-size: 20px;
  }

  .benefits .cardWrapper .card {
    border-radius: 12px;
  }
  .whyChoose .subHeading {
    max-width: 100%;
  }
  .benefits .benefitWrapper .rightWrapper .title {
    font-size: 26px;
  }

  .benefits .desc,
  .benefits .subHeading {
    max-width: 400px;
  }

  .profit .name::after {
    display: none;
  }

  .profit .column {
    padding-bottom: 50px;
  }

  .profit .column .title {
    font-size: 28px;
  }

  .profit .name {
    font-size: 40px;
    max-width: 100%;
  }

  .profit .row.last {
    padding-bottom: 0;
  }

  .profit {
    padding-bottom: 0;
  }

  .profit .col-md-5 {
    width: 100%;
  }

  .profit .col-md-2 {
    width: 100%;
    justify-content: flex-start;
  }

  .profit .row {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .col-reverse {
    flex-direction: column-reverse;
  }

  .profit .column.last {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 576px) {
  .blogPage .gridWrapper {
    grid-template-columns: auto;
  }
  .heroSection {
    padding: 100px 0px;
  }
  .heroSection .heading {
    font-size: 34px;
  }

  .heroSection .subHeading {
    font-size: 16px;
  }

  .blogs .heading {
    font-size: 24px;
  }

  .blogs .gridWrapper {
    padding-top: 30px;
  }

  .footer .wrapper {
    padding-inline: 15px;
    overflow-y: hidden;
  }

  .footer marquee {
    font-size: 30px;
    padding-bottom: 20px;
  }

  .footer .leftWrapper img {
    height: 200px;
  }

  .footer .grid-3 .card {
    padding: 20px;
    border-radius: 24px;
  }

  .footer .grid-3 .card::before {
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
  }

  .footer .grid-3 {
    padding-bottom: 40px;
  }

  .footer .grid-3 .card .heading {
    font-size: 24px;
    padding-bottom: 20px;
  }
  .footer .grid-3 .card svg {
    width: 100px;
    height: 100px;
  }

  .footer .card .location {
    font-size: 18px;
  }

  .footer .card .address {
    font-size: 16px;
  }

  .footer .grid-3 .card .about {
    font-size: 16px;
    padding-top: 0;
  }

  .footer .desc {
    font-size: 24px;
  }

  .footer .shortDesc {
    font-size: 20px;
  }

  .footer .card .socialWrapper {
    max-width: 100%;
  }

  .footer .copyright {
    font-size: 16px;
  }

  footer {
    padding-top: 60px;
  }

  .header svg {
    max-width: 170px;
  }

  .benefits .benefitWrapper {
    flex-direction: column;
    padding-bottom: 60px;
    gap: 10px;
  }

  .benefits .cardWrapper {
    grid-template-columns: auto;
  }
  .benefits .benefitWrapper .number {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    font-size: 16px;
  }
  .benefits .benefitWrapper .rightWrapper .title {
    font-size: 24px;
    max-width: 100%;
  }

  .benefits .benefitWrapper:not(:last-child) .number::after {
    display: none;
  }

  .benefitBottom {
    border-radius: 14px;
  }

  .benefitBottom .wrapper {
    padding: 60px 0px;
  }

  .benefitBottom .iconBox {
    width: 80px;
    height: 80px;
    top: -40px;
    padding: 20px;
  }

  .benefitBottom .wrapper .heading {
    font-size: 22px;
  }

  .benefitBottom .wrapper .subHeading {
    font-size: 14px;
    padding-top: 5px;
  }

  .benefitBottom .wrapper .desc {
    font-size: 14px;
  }

  .benefitBottom .wrapper svg {
    height: 150px;
    width: 27px;
  }

  .benefitBottom .container {
    padding-inline: 10px;
  }

  .benefits .rightImg {
    display: none;
  }

  .crypto .leftHeading,
  .crypto .rightHeading {
    max-width: 100%;
    text-align: center;
    font-size: 26px;
    position: relative;
    z-index: 2;
  }

  .crypto .leftHeading {
    max-width: 80%;
    margin-inline: auto;
  }

  .crypto .heading {
    font-size: 32px;
  }

  .crypto .gridWrapper {
    grid-template-columns: auto;
    padding: 0 0 0 10px;
    gap: 50px;
  }

  .benefits .cardWrapper .card {
    padding: 20px 15px;
  }

  .benefits .cardWrapper .card .cardTitle {
    font-size: 18px;
  }

  .blogs .gridWrapper {
    grid-template-columns: auto;
  }

  .whyChoose .desc {
    font-size: 16px;
    line-height: 28px;
  }

  .partnerWrapper .box img {
    max-height: 70px;
    max-width: 100%;
  }
  .partnerWrapper .box {
    height: 130px;
    padding: 10px;
  }

  .partnerWrapper {
    padding-bottom: 50px;
  }

  .howItWorks {
    padding-top: 50px;
  }

  .whyChoose .heading,
  .howItWorks .heading {
    font-size: 32px;
  }

  .howItWorks .cardWrapper .card {
    padding: 20px;
  }

  .howItWorks .cardWrapper .card .desc {
    font-size: 14px;
    line-height: 26px;
    padding-top: 10px;
  }

  .howItWorks .cardWrapper {
    gap: 20px;
  }

  .howItWorks .cardWrapper .card .number {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }

  .partners .heading {
    font-size: 30px;
  }

  .whyChoose .subHeading {
    font-size: 28px;
  }

  .heroBottom .heroImg {
    padding-top: 0;
  }

  .heroBottom .heroImg img {
    max-width: 100%;
  }

  .whyChoose {
    padding-top: 80px;
  }

  .hero .join {
    padding: 14px;
    font-size: 16px;
    max-width: 200px;
  }

  .hero .heading {
    font-size: 38px;
  }

  .hero .subHeading {
    font-size: 20px;
  }

  .whyChoose .myArbit {
    width: 175px;
  }

  .howItWorks .cardWrapper .card .title {
    font-size: 25px;
    padding-top: 15px;
  }

  .crypto,
  .crypto .cryptoBottom {
    padding-top: 80px;
  }

  .heroBottom .socialMedia a {
    font-size: 16px;
  }

  .hero {
    padding-top: 160px;
  }

  .whyChoose .bg {
    width: 100%;
    height: 330px;
  }

  .hero .rightCoin {
    display: none;
  }

  .hero .rightCoin img,
  .hero .leftCoin img {
    height: 150px;
  }

  .faq {
    padding-top: 50px;
  }

  .heading {
    font-size: 26px;
  }

  .faq .subHeading {
    font-size: 18px;
  }

  .row-title {
    font-size: 18px !important;
  }

  .row-content-text {
    font-size: 16px !important;
  }

  .contactUs .pinkCircle,
  .faq .pinkCircle {
    left: 0;
  }

  .aboutUs .gridWrapper .leftWrapper .heading {
    font-size: 30px;
  }

  .aboutUs .gridWrapper .leftWrapper .desc {
    font-size: 18px;
    line-height: 30px;
  }

  .aboutUs .content .desc {
    max-width: 100%;
    font-size: 18px;
  }

  .aboutUs .bitcoin {
    padding-bottom: 0;
  }

  .aboutUs .content .subHeading {
    padding-top: 60px;
    font-size: 26px;
    max-width: 100%;
  }

  .aboutUs .register {
    font-size: 16px;
  }

  .blogs .desc {
    max-width: 90%;
    font-size: 16px;
  }

  .benefitBottom {
    margin-top: 70px;
  }

  .blogs .subHeading {
    font-size: 24px;
  }

  .blogs .heading .icon,
  .blogs .heading .icon a {
    width: 40px;
    height: 40px;
  }

  .footer .rightWrapper a {
    font-size: 16px;
  }

  .blogs {
    padding-top: 60px;
  }

  .blogDetail .content .gridWrapper {
    grid-template-columns: auto;
  }

  .blogDetail .content .gridWrapper p {
    max-width: 100%;
  }

  .blogDetail .content .pinkTitle {
    font-size: 22px;
  }

  .blogDetail .authorBox {
    padding: 20px;
    max-width: 100%;
  }

  .blogDetail .authorBox .author {
    font-size: 18px;
  }

  .blogDetail .postHead .nameWrapper img {
    width: 50px;
    height: 50px;
  }

  .blogDetail {
    padding-top: 50px;
  }

  .blogDetail .postHead .nameWrapper .name {
    font-size: 18px;
  }

  .blogDetail .postHead .date {
    font-size: 16px;
  }

  .blogDetail .content .heading {
    font-size: 22px;
  }

  .blogDetail .content .mainHeading {
    font-size: 28px;
  }

  .blogDetail .content p {
    font-size: 16px;
  }

  .benefits .benefitWrapper .rightWrapper .shortDesc {
    font-size: 16px;
  }

  .benefits {
    padding-top: 80px;
  }

  .benefits .heading {
    font-size: 25px;
  }

  .benefits .subHeading {
    font-size: 24px;
    max-width: 100%;
  }

  .features {
    padding-top: 80px;
  }

  .descBox {
    width: 100%;
    padding-top: 0;
  }

  .features .desc {
    line-height: 28px;
    font-size: 16px;
    max-width: 100%;
  }

  .features .icon-box {
    width: 50%;
    height: 170px;
    padding: 15px 0px;
  }

  .features .icon-box svg {
    width: 45px;
    height: 45px;
  }

  .features .icon-box .subHeading {
    font-size: 16px;
    max-width: 95px;
  }
  .row .icon-box.investment {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }

  .row .icon-box.transaction {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5);
  }

  .row.second .icon-box.flexible {
    border-left: 1px solid rgba(255, 255, 255, 0.5);
  }

  .row.second .icon-box.easy {
    border-right: 1px solid rgba(255, 255, 255, 0.5);
  }

  .row.second .icon-box.profitiers {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }

  .row.second .icon-box.reward {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5);
  }

  .features .heading {
    font-size: 32px;
    flex-wrap: wrap;
  }

  .features .heading svg {
    width: 170px;
    height: 50px;
  }

  .cryptoBottom svg.smNone {
    display: none;
  }

  .crypto .cryptoBottom svg {
    width: 60px;
    height: 80px;
    left: 50%;
    transform: translateX(-50%);
    top: 170px;
  }

  .crypto .rightHeading {
    padding-top: 120px;
  }

  .profit .table {
    padding: 20px 15px;
    border-radius: 12px;
  }

  .profit {
    padding-top: 50px;
  }

  .profit .name {
    font-size: 32px;
  }

  .profit .column .title {
    font-size: 22px;
  }

  .profit .table .tableHead {
    font-size: 14px;
    padding-bottom: 20px;
  }
  .profit .table .tableBody {
    font-size: 12px;
    padding: 10px 0px;
  }

  .footer {
    border-radius: 12px;
  }

  .contactUs {
    padding-top: 50px;
  }

  .contactUs .gridWrapper {
    grid-template-columns: auto;
    gap: 50px;
  }

  .contactUs .infoBox .info a {
    font-size: 18px;
  }

  .contactUs .infoBox .label {
    font-size: 16px;
  }

  .contactUs .infoBox {
    padding: 20px 0 20px 20px;
  }

  .contactUs .infoBox .info {
    padding-bottom: 30px;
  }

  .contactUs .rightWrapper {
    padding: 20px 15px;
    border-radius: 12px;
  }

  .navbar a {
    font-size: 20px;
  }

  .crypto .cryptoBottom svg.mdNone {
    display: block;
  }

  .hero .btnWrapper {
    display: flex;
    column-gap: 10px;
    position: absolute;
    top: -140px;
    padding: 0;
    right: 20px;
  }

  .aboutUs .gridWrapper {
    padding-top: 50px;
  }

  .hero .btnWrapper a {
    font-size: 14px;
    padding: 8px 16px;
  }

  .leftCoinMbl {
    display: block;
    position: absolute;
    left: 0;
    top: -140px;
  }

  .leftCoinMbl img {
    width: 100px;
    object-fit: contain;
  }
  .rightWrapper iframe {
    height: 250px;
  }
}
